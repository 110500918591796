import React from "react";
import "./ImageContainer.css";

function ImageContainer(props) {
  return (
    <div className="img-container">
      <h2>{props.title}</h2>
      <a href={props.image} target="_blank" rel="noreferrer noopener">
        <img src={props.image} alt={props.alt} />
      </a>
    </div>
  );
}

export default ImageContainer;
