import "./App.css";
import { Route, Routes } from 'react-router-dom';
import Footer from "./components/Footer/Footer";
import Home from "./sections/Home/Home.js";
import Artwork from "./sections/Artwork/Artwork.js"

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home/>}/>
        <Route path="/artwork" element={<Artwork/>}/>
      </Routes>
      <Footer />
    </>
  );
}

export default App;
