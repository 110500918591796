import React from "react";
import { useState, useRef } from "react";
import Socials from "../../components/Socials/Socials";
import emailjs from "@emailjs/browser";
import "./Contact.css";

// Used this tutorial for emailjs: https://medium.com/@thomasaugot/create-a-react-contact-form-with-email-js-cad2c8606f33
// Used this tutorial for netlify form integration (which didn't work): https://www.youtube.com/watch?v=gi-iKLkB8R8
function Contact() {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [stateMessage, setStateMessage] = useState(null);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const form = useRef();

  const clearContactForm = () => {
    setEmail("");
    setMessage("");
    setName("");
  };

  const messageTimeout = () => {
    setTimeout(() => {
      setStateMessage(null)
      setIsSubmitting(false)
    }, 5000)
  }

  const sendEmail = (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    if (message === "" || email === "" || name === "") {
      setStateMessage("Please fill in all fields.");
      messageTimeout();
    } else {
      emailjs
        .sendForm("service_dopy52m", "template_j6a26ok", form.current, "P70onHL57UNv3dHw8")
        .then(
          (result) => {
            setStateMessage("Message sent!");
            clearContactForm();
            messageTimeout();
          },
          (error) => {
            setStateMessage("Something went wrong, please try again later.");
            messageTimeout();
          }
        );
    }
  };

  return (
    <main className="contact-wrapper" id='contact'>
      <div className="left">
        <span>
          For commissions and project inquiries, please email{" "}
          <i>itsconnell@gmail.com</i>, submit a message via this form, or
          contact me on social media.
        </span>
        <Socials />
      </div>
      <div className="right">
        {/* input name attributes must match the variables used in the emailjs template */}
        <form ref={form} name="contact" onSubmit={sendEmail}>
          <input type="hidden" name="form-name" value="contact" />
          <label htmlFor="name">Name</label>
          <input
            type="text"
            name="name"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
            aria-label="name"
          />
          <label htmlFor="email">Email</label>
          <input
            type="email"
            name="email"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            aria-label="email"
          />
          <label htmlFor="message">Message</label>
          <textarea
            name="message"
            value={message}
            onChange={(e) => {
              setMessage(e.target.value);
            }}
            aria-label="message"
          ></textarea>
          {stateMessage && <span className="submit-msg">{stateMessage}</span>}
          <button type="submit" disabled={isSubmitting} aria-label="submit button">
            Submit
          </button>
        </form>
      </div>
    </main>
  );
}

export default Contact;
