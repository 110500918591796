import "./Artwork.css"
import Gallery from "../../components/Gallery/Gallery.js"

function Artwork() {
    return(
        <Gallery/>
    )
}

export default Artwork;
