import bossfight from "./assets/bossfight.webp";
import chard from "./assets/chard_turnaround.webp";
import drawtech from "./assets/drawtech_final.webp";
import edward from "./assets/edward_sketches.webp";
import pfp from "./assets/fall_pfp.webp";
import fella from "./assets/fella_doodle.webp";
import foodies from "./assets/foodies.webp";
import halloween from "./assets/halloween_2023_ahhh.webp"
import turnaround from "./assets/turnaround.webp";

const images = [
  { id: 1, image: bossfight, title: "bossfight", alt: "bossfight" },
  { id: 2, image: chard, title: "Chardalyn", alt: "chardalyn turnaround" },
  { id: 3, image: drawtech, title: "drawtech", alt: "drawtech final " },
  { id: 4, image: edward, title: "Edward Sketches", alt: "Edward Sketches" },
  { id: 5, image: pfp, title: "Fall Profile Picture", alt: "Fall Profile Picture" },
  { id: 6, image: fella, title: "Him", alt: "you found me!" },
  { id: 7, image: foodies, title: "foodies", alt: "foodies" },
  { id: 8, image: halloween, title: "Halloween Scary", alt: "Halloween Scary" },
  { id: 9, image: turnaround, title: "turnaround", alt: "turnaround" },
];

export default images;
