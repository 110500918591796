import React from "react";
import "./Socials.css";
import { FaInstagram } from "react-icons/fa";
import { FaBluesky } from "react-icons/fa6";
import { GiTank } from "react-icons/gi";

function Socials() {
  return (
    <div className="socials">
      <a
        href="https://www.instagram.com/itsconnell"
        target="_blank"
        rel="noreferrer noopener"
        title="instagram"
        role="presentation"
      >
        <FaInstagram />
      </a>
      <a
        href="https://bsky.app/profile/itsconnell.bsky.social"
        target="_blank"
        rel="noreferrer noopener"
        title="bluesky"
        role="presentation"
      >
        <FaBluesky />
      </a>
      <a
        href="https://itsconnell.newgrounds.com/"
        target="_blank"
        rel="noreferrer noopener"
        title="newgrounds"
        role="presentation"
      >
        <GiTank />
      </a>
    </div>
  );
}

export default Socials;
