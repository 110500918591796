import React from "react";
import "./Gallery.css";
import ImageContainer from "../ImageContainer/ImageContainer.js";
import images from "../../images.js";

function Gallery() {
  const album = images.map((image) => {
    return (
      <ImageContainer
        key={image.id}
        image={image.image}
        alt={image.alt}
        title={image.title}
      />
    );
  });
  return <section className="gallery">{album}</section>;
}

export default Gallery;
