import React from "react";
import { HashLink } from 'react-router-hash-link';
import { Link } from 'react-router-dom';
import "./Header.css";

function Header() {
  return (
    <header>
      <div className="logo">
        <h1>Brendan Connell's Portfolio</h1>
      </div>
      <nav>
        <HashLink smooth to="#home">
          Home
        </HashLink>
        <Link to="/artwork" target="__blank">
          Artwork
        </Link>
        <HashLink smooth to="#contact">
          Contact
        </HashLink>
      </nav>
    </header>
  );
}

export default Header;
